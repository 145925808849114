import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Web3ReactProvider } from "@web3-react/core";
import { ChakraProvider } from '@chakra-ui/react';
import { WEB3PROPS } from "./utils/web3";
import { Buffer } from 'buffer';

window.Buffer = window.Buffer || Buffer;

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  // <React.StrictMode>
    <Web3ReactProvider {...WEB3PROPS}>
      <ChakraProvider>
        <App />
      </ChakraProvider>
    </Web3ReactProvider>
  // </React.StrictMode>
);