import { useState } from "react";
import { css, StyleSheet } from "aphrodite";
import { UIColors } from "../utils/colors";

const SectionLabel = ({ children }) => {


  return (
    <div className={css(styles.sectionLabel)}>
      {children}
    </div>
  )
};

const styles = StyleSheet.create({
  sectionLabel: {
    color: UIColors.white(),
    fontFamily: "'Poppins'",
    fontWeight: 700,
    fontSize: 16.4,
    // marginBottom: 10,
    textAlign: "left",

  }
});

export default SectionLabel;