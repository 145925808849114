import { useState } from "react";
import { css, StyleSheet } from "aphrodite";
import { useToast } from '@chakra-ui/react';
import { UIColors } from "../utils/colors";
import { useWeb3React, Web3ReactProvider } from "@web3-react/core";
import { truncateAddress } from '../utils/helpers';
import { getEnsFromAddress } from '../utils/web3';
import Countdown from 'react-countdown';
const CloseIcon = "/close.svg";


const Banner = ({ 
  label = "", 
  children,
  userClaimedCount,
  isUserAllowedToMint
}) => {
  const {  
    library,
    chainId,
    account,
    activate,
    deactivate,
    active 
  } = useWeb3React();
  const toast = useToast();

  const [isHovered, setIsHovered] = useState(false);

  const date = new Date(Date.UTC(2022, 7, 21, 17, 0, 0));


  const disconnect = () => {
    toast.closeAll()
    deactivate();
    toast({
      title: 'Wallet Disconnected.',
      duration: 4000,
      isClosable: true,
      position: 'right',
    })
  };

  const renderWalletIcon = () => {
    const { provider } = library;

    if (provider?.isCoinbaseWallet) {
      return (
        <img
          src={"/cbw.png"}
          className={css(styles.metamask)}
          alt={"Coinbase Wallet Icon"}
        />
      )
    } else {
      return (
        <img
          src={"/mm.png"}
          className={css(styles.metamask)}
          alt={"MetaMask Icon"}
        />
      )
    }
  } 
  const renderBannerMessage = () => {
    if (active && account && isUserAllowedToMint) {
      // const tokenAllowance = 5;
      return `PACs in Wallet: ${userClaimedCount}`
    } 
    return null;
  };

  const renderBannerLabel = () => {

    if (active && account) {
      const address = truncateAddress(account);


      return (
        <div 
          className={css(styles.walletSummaryContainer)}
          isHovered
        >
          <div className={css(styles.row)}>
            <div className={css(styles.group)}>
              {renderWalletIcon()}
              {`Connected: ${address}`}
            </div>
            <div
              className={css(styles.disconnectButton)}
              onClick={disconnect}
            > 
              {"Disconnect"}
            </div>
          </div>
          <div className={css(styles.message)}>
            {renderBannerMessage()}
          </div>
        </div>
      )
    } else if (!active || !account) {
      return label 
        ? label : children ?
         children : (
          <span style={{ lineHeight: 2, fontSize: 15 }}>
            <span style={{ fontWeight: 'bold', textTransform: 'uppercase', fontSize: 16}}>
              {'Phase 2 mint is live.'}
            </span>
            <br/>
            {"All Phase 2 mint funds will be used to acquire assets for PAC holders. Every single ETH. For details on mint plans and holder perks, join our "}
            <a href={"https://discord.com/invite/thepacnft"} rel="noopener noreferrer" target="_blank" className={css(styles.hyperlink)} >
              {"Discord community"}
            </a>
            {"."}
          </span>
         );
    }
  }

  return (
    <div 
      className={css(styles.banner)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <span className={css(styles.bannerLabel)}>
        {renderBannerLabel()}
      </span>
    </div>
  )
};

const styles = StyleSheet.create({
  banner: {
    position: "relative",
    background: UIColors.bannerBlue(),
    border: `1px solid ${UIColors.bannerBorderBlue()}`,
    borderRadius: 8,
    width: "100%",
    padding: 12,
    marginBottom: 20,
    boxSizing: "border-box",
    "@media only screen and (max-width: 415px)": {
      left: '50%',
      marginLeft: '-50vw',
      marginRight: '-50vw',
      maxWidth: '100vw',
      position: 'relative',
      right: '50%',
      width: '100vw',
      borderRadius: 0
    }
  },
  bannerLabel: {
    color: UIColors.white(),
    fontSize: 18,
    fontWeight: 600,
    fontFamily: "Poppins",
    whiteSpace: "pre-wrap",
    "@media only screen and (max-width: 415px)": {
      fontSize: 15
    }
  },
  walletSummaryContainer: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column"
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  message: {
    marginTop: 10,
    whiteSpace: "pre-wrap"
  },
  group: {
    display: "flex",
    alignItems: "center",
    // fontWeight: 'bold',
    // fontSize: 20,
    "@media only screen and (max-width: 767px)": {
     marginTop: 20
    },
    "@media only screen and (max-width: 415px)": {
      marginTop: 35

    }
  },
  metamask: {
    height: 20,
    width: 20,
    marginRight: 10
  },  
  closeButton: {
    filter:  "brightness(0) invert(1)",
    cursor: "pointer",
    transition: "all ease-out 0.2s",
    ":hover": {
      filter: "unset"
    }
  },
  disconnectButton: {
    position: "absolute",
    top: 8,
    right: 8,
    cursor: "pointer",
    // outline: "none",
    // highlight: "none",
    // border: "none",
    textTransform: "uppercase",
    color: UIColors.black(),
    fontSize: 12,
    fontWeight: 700,
    background: UIColors.white(),
    borderRadius: 4,
    padding: "5px 10px",
    textAlign: "center",
    transition: "all ease-out 0.2s",
    ":hover": {
      background: UIColors.blue(),
    },

    ":hover": {
      textDecoration: "underline"
    }
  },
  hyperlink: {
    width: "100%",
    // color: UIColors.bannerBorderBlue(),
    textDecoration: "unset",
    cusor: "pointer",
    textDecoration: "underline"
  }
});

export default Banner;