
export const UIColors = {
  "black": (a = 1) => `rgba(0, 0, 0, ${a})`,
  "white": (a = 1) => `rgba(255, 255, 255, ${a})`,
  "blue": (a = 1) =>  `rgba(79, 160, 255, ${a})`,
  "bannerBlue": (a = 1) => `rgba(23, 72, 131, ${a})`,
  "bannerBorderBlue": (a = 1) => `rgba(49, 117, 198, ${a})`,
  "gray": (a = 1) => `rgba(157, 157, 157, ${a})`,
  "red": (a = 1) => `rgba(255, 93, 105, ${a})`,

}