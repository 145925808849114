import './App.css';
import { useWeb3React } from "@web3-react/core";
import { useEffect, useState, useMemo } from "react";
import { css, StyleSheet } from "aphrodite";


// Components
import Banner from "./components/Banner";
import MintButton from './components/MintButton';
import Section from "./components/Section";
import Input from "./components/Input";
import Footer from "./components/Footer";
import MintModal from "./components/MintModal";
import { UIColors } from './utils/colors';

import { 
  getClaimedSupply,
  getUnclaimedSupply,
  getUserTokenSupply,
  mintNFTs
 } from './utils/web3';
import { 
  isEligibleToMint 
} from './utils/allowlist';
import { useToast } from '@chakra-ui/react'


const SUPPLY_LIMIT = 2000;


export default function App() {
  const toast = useToast();
  const [numOfTokens, setNumOfTokens] = useState(1);

  const [isFetchingTokenCount, setIsFetchingTokenCount] = useState(false);
  const [claimedCount, setClaimedCount] = useState(0);

  const [isFetchingUserTokenCount, setIsFetchingUserTokenCount] = useState(false);
  const [userClaimedCount, setUserClaimedCount] = useState(0);

  const [isUserAllowedToMint, setIsUserAllowedToMint] = useState(false);
  const [isUserMinting, setIsUserMinting] = useState(false);
  const [isMintModalOpen, setIsMintModalOpen] = useState(false);
  const [txSummary, setTxSummary] = useState(null);

 
  const {  
    library,
    chainId,
    account,
    activate,
    deactivate,
    active 
  } = useWeb3React();


  useEffect(() => {
    getTokenSupply();
  }, []);
  
  useEffect(() => {
    if (active && account) {
      onConnect();
    } else if (!active || !account) {
      onDisconnect();
    }
  }, [account, active]);


  async function getTokenSupply() {
    setIsFetchingTokenCount(true);
    const claimedSupply = await getClaimedSupply();
    setClaimedCount(claimedSupply);
    setIsFetchingTokenCount(false);
  };

  async function getUserTokenCount() {
    setIsFetchingUserTokenCount(true);
    const userTokenCount = await getUserTokenSupply(account);
    setUserClaimedCount(userTokenCount);
    setIsFetchingUserTokenCount(false);
  };

  async function getUserEligibility() {
    const userEligibilty = true
    setIsUserAllowedToMint(userEligibilty);
  };  

  async function onConnect() {
    getTokenSupply();
    getUserEligibility();
    getUserTokenCount();
  };

  async function onDisconnect() {
    setIsUserAllowedToMint(false);
    setIsFetchingTokenCount(false);
    setIsFetchingUserTokenCount(false);
    setUserClaimedCount(0);
    setNumOfTokens(1);
  };

  async function onSuccess() {
    getUserEligibility();
    getUserTokenCount();
    getTokenSupply();
  }

  async function switchToMainnet() {
    if (!window || !window.ethereum) return;
  
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: "0x1"
          },
        ],
      });
    } catch (error) {
      toast({
        title: "Mainnet is required to mint",
        description: "Please switch your network to Mainnet Ethereum and try again.",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: 'right',
      });
      throw new Error();
    }
  };

  


  async function mint() {
    setIsUserMinting(true);

    if (numOfTokens < 1) {
      setIsUserMinting(false);
      return toast({
        title: "At least one NFT must be minted.",
        description: "Please adjust the quantity and try again!",
        duration: 4000,
        isClosable: true,
        position: 'top',
      })
    }

    

    // const remainingSupply = await getUnclaimedSupply();

    // if (remainingSupply === 0) {
    //   setIsUserMinting(false);
    //   return toast({
    //     title: "All available NFTs have been minted.",
    //     description: "Please visit OpenSea or another secondary marketplace to purchase a PAC NFT.",
    //     duration: 4000,
    //     isClosable: true,
    //     position: 'top',
    //   })
    // };

    const claimedSupply = await getClaimedSupply();

    if (claimedSupply >= SUPPLY_LIMIT) {
      setIsUserMinting(false);
      return toast({
        title: "Phase 2 minting's limit is set to 2000.",
        description: "Please visit OpenSea or the secondary market to own a PAC. Join our Discord to receive updates!",
        duration: 4000,
        isClosable: true,
        position: 'top',
      })
    };

    if ((Number(claimedSupply) + Number(numOfTokens)) > SUPPLY_LIMIT) {
      setIsUserMinting(false);
      return toast({
        title: "Supply cannot exceed 2000 at the moment.",
        description: "Please adjust the number you'd like to mint.",
        duration: 4000,
        isClosable: true,
        position: 'top',
      })
    };

    
    try {
      setTxSummary(null);
      await switchToMainnet();
      const response = await mintNFTs({
        address: account,
        library,
        chainId,
        numOfTokens,
        onMintStart: () => {
          setIsMintModalOpen(true);
        },
      });
      setTxSummary(response);
      setIsUserMinting(false);
    } catch (err) {
      setIsUserMinting(false);
    }
  } 



  const renderSupplyCount = () => {
    const currSupplyCount = Number(claimedCount);

    return (isFetchingTokenCount || !currSupplyCount)
      ? <i className={css(styles.loadingIcon) + " fad fa-spinner-third fa-spin"} />
      : currSupplyCount
  };

  const renderSupplyCap = () => {
    const currSupplyCount = Number(claimedCount);

    if (isFetchingTokenCount || !currSupplyCount) {
      return <i className={css(styles.loadingIcon) + " fad fa-spinner-third fa-spin"} />
    };

    if (currSupplyCount < 1700) {
      return 1700;
    } else if (currSupplyCount >= 1700 && currSupplyCount < 1800) {
      return 1800;
    } else if (currSupplyCount >= 1800 && currSupplyCount < 1900) {
      return 1900;
    } else if (currSupplyCount >= 1900 && currSupplyCount <= 2000) {
      return 2000;
    }
  }

  const formatBatchNumber = () => { 
    const currSupplyCount = Number(claimedCount);

    if (currSupplyCount < 1700) {
      return 2;
    } else if (currSupplyCount >= 1700 && currSupplyCount < 1800) {
      return 3;
    } else if (currSupplyCount >= 1800 && currSupplyCount < 1900) {
      return 4;
    } else if (currSupplyCount >= 1900 && currSupplyCount <= 2000) {
      return 5;
    }
  };

  return (
    <div className={css(styles.wrapper)}>
      <div className={css(styles.app)}>
        <Banner 
          userClaimedCount={userClaimedCount} 
          isUserAllowedToMint={isUserAllowedToMint}
        />
        <div className={css(styles.column)}>
          <Section label={"Total minted"}>
            <span className={css(styles.supplyCount)}>
              {renderSupplyCount()}
              {'/'}{renderSupplyCap()}
              <span className={css(styles.batchLabel)}>
                {` (Phase 2 Batch ${formatBatchNumber()} Supply)`}
              </span>
            </span>
          </Section>
          <Section label={"Price"} style={styles.priceContainer}>
            <span className={css(styles.price)}>
              {"0.04 eth + gas"}
            </span>
          </Section>
        </div>
        <Section label={"How many PACs to mint? (Max. 10 per tx)"}>
          <Input 
            value={numOfTokens} 
            onChange={(value) => setNumOfTokens(value)}
            MAX={10}
          />
        </Section>
        <MintButton 
          numOfTokens={numOfTokens} 
          isUserAllowedToMint={isUserAllowedToMint}
          isUserMinting={isUserMinting}
          isFetchingUserTokenCount={isFetchingUserTokenCount}
          mint={mint}
          claimedCount={claimedCount}
        />
        <Footer />
      </div>
      <MintModal 
        isOpen={isMintModalOpen}
        onClose={() => {
          setTxSummary(null);
          setIsMintModalOpen(false)
        }}
        txSummary={txSummary}
        onSuccess={onSuccess}
      />
    </div>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    background: UIColors.black(),
    width: "100vw",
    height: "100vh",

  },
  app: {
    background: UIColors.black(),
    width: "100%",
    minHeight: "100vh",
    height: 'auto',
    maxWidth: 1200,
    margin: "0 auto",
    boxSizing: "border-box",
    padding: "30px 30px 20px 0",
    "@media only screen and (max-width: 415px)": {
      padding: "50px 20px 30px 20px"
    }
  },
  column: {
    display: "flex",
    alignItems: "flex-start",
    // flexDirection: "row",
    flexDirection: "column",
    justifyContent: "flex-start",

  },
  loadingIcon: {
    color: UIColors.blue(),
    fontSize: 22 
  },
  row: {
    display: "flex",
    alignItems: "center"
  },
  metamask: {
    height: 30,
    width: 30,
    marginRight: 10
  },  
  supplyCount: {
    display: "inline-flex",
    justifyContent: "flex-start",
    alignItems: "center",
    color: UIColors.blue(),
    lineHeight: 1,
    fontSize: 42,
    fontWeight: 600,
    marginTop: 5,
    "@media only screen and (max-width: 415px)": {
      fontSize: 30
    }
  },
  batchLabel: {
    fontSize: 16, 
    marginLeft: 10,
    "@media only screen and (max-width: 415px)": {
      fontSize: 12
    }
  },
  priceContainer: {

  },
  price: {
    color: UIColors.blue(),
    lineHeight: 1,
    textAlign: "left",
    fontSize: 24,
    fontWeight: 600,
    "@media only screen and (max-width: 415px)": {
      fontSize: 18
    }
  }
})