import { css, StyleSheet } from "aphrodite";
import { useState, useEffect} from "react";
import { useWeb3React } from "@web3-react/core";
import { ChevronDownIcon } from '@chakra-ui/icons';

import { CONNECTORS, setProvider, mintNFTs } from "../utils/web3"
import { UIColors } from "../utils/colors";

import SectionLabel from "./SectionLabel";
import ConnectButton from "./ConnectButton";

const SUPPLY_CAP = 2000;

const MintButton = ({ 
  numOfTokens,
  isUserAllowedToMint,
  isUserMinting,
  isFetchingUserTokenCount,
  mint,
  claimedCount
 }) => {

  const {  
    library,
    chainId,
    account,
    activate,
    active 
  } = useWeb3React();
  
  const TOTAL = (0.04 * Number(numOfTokens)).toFixed(2);
  const isMinting = isUserMinting || isFetchingUserTokenCount;
  const isExceeded = Number(claimedCount) >= SUPPLY_CAP;
  const isDisabled = active && account && isExceeded;

  const handleClick = () => {
    if (!active || !account) {
      activate(CONNECTORS.injected);
      setProvider("mm");
    } else if (isDisabled) {
      return null;
    } else if (!isExceeded && active && account && library) {
      return mint();
    }
  };


  const handleButtonLabel = () => {
    if (!active || !account) {
      return "Connect Wallet"
    } else if (!isUserAllowedToMint) {
      const isPlural = numOfTokens > 1;
      return isPlural ? "Mint PAC NFTs" :  "Mint a PAC NFT";
    } else if (isUserMinting) {
      return "Minting..."
    } else if (isFetchingUserTokenCount) {
      return "Loading..."
    } else if (isUserAllowedToMint && account && active) {
      const isPlural = numOfTokens > 1;
      return isPlural ? "Mint PAC NFTs" :  "Mint a PAC NFT";
    }
  };

  const renderButton = () => {

    const button = (
      <button 
        className={css(styles.button, (isMinting || isDisabled) && styles.disabled)}
        onClick={isDisabled ? null : handleClick}
      > 
        {handleButtonLabel()}
      </button>
    );

    return (!active || !account) 
      ? <ConnectButton button={button} />
      : button;
  }

  return (
    <div className={css(styles.buttonContainer, isDisabled && styles.disabledWrapper) + " animatedBG"}>
      <div className={css(styles.section)}>
        <SectionLabel>
          {"Total"}
        </SectionLabel>
        <div className={css(styles.totalContainer)}>
          <span className={css(styles.total)}>
            {TOTAL}
          </span>
          <span className={css(styles.totalSuffix)}>
            {"eth + gas"}
          </span>
        </div>
      </div>
      {renderButton()}
    </div>
  )
};

const styles = StyleSheet.create({
  buttonContainer: {
    marginTop: 40,
    backgroundSize: "1000% 1000%",
    padding: "6px 10px",
    borderRadius: 8,
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media only screen and (max-width: 415px)": {
      flexDirection: "column",
      alignItems: "flex-start",
      padding: 20,
      left: '50%',
      marginLeft: '-50vw',
      marginRight: '-50vw',
      maxWidth: '100vw',
      position: 'relative',
      right: '50%',
      width: '100vw',
      borderRadius: 0
    }
  },
  button: {
    cursor: "pointer",
    outline: "none",
    highlight: "none",
    border: "none",
    textTransform: "uppercase",
    color: UIColors.black(),
    fontSize: 16,
    fontWeight: 700,
    background: UIColors.white(),
    borderRadius: 4,
    padding: "15px 30px",
    textAlign: "center",
    transition: "all ease-out 0.2s",
    opacity: 1,
    ":hover": {
      background: UIColors.blue(),
      color: UIColors.white()
    },
    "@media only screen and (max-width: 415px)": {
      marginTop: 20,
      width: "100%"

    }
  },
  disabledWrapper: {
    cursor: "not-allowed",
  },
  disabled: {
    opacity: 0.6,
    pointerEvents: "none"
  },
  totalContainer: {
    display: "flex",
    alignItems: "flex-end",
  },
  total: {
    color: UIColors.white(),
    border: 0,
    fontSize: 28,
    padding: 0,
    width: 78,
    fontWeight: 600,
    height: 42.5,
    display: "flex",
    alignItems: "center",
    paddingBottom: 3
  },
  totalSuffix: {
    display: "flex",
    alignItems: "center",
    color: UIColors.white(),
    fontSize: 16,
    fontWeight: 700,
    height: 42.5

  },
  section: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    justifyContent: "center",
    paddingLeft: 5,
    paddingTop: 5
  },
  loadingIcon: {
    color: UIColors.blue(),
    fontSize: 25
  }
});

export default MintButton;