import { ThirdwebSDK } from "@thirdweb-dev/sdk";
import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import { ethers } from "ethers";
// import ENS, { getEnsAddress } from "@ensdomains/ensjs";
// const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const alchemyKey = process.env.REACT_APP_ALCHEMY_API;

// const web3 = createAlchemyWeb3(alchemyKey);
// window.web3 = web3;

//todo: update this to mainnet
export const SDK = new ThirdwebSDK("ethereum", {
  readonlySettings: {
    rpcUrl: process.env.REACT_APP_ALCHEMY_API_ENDPOINT
  },
});

export const CONTRACT_ADDRESS = "0x85829BF847f0e9417065a22a1b73B35BbC3Dc535";

export const CONTRACT = SDK.getSignatureDrop(CONTRACT_ADDRESS);

export const SUPPORTED_CHAINIDS = [
  1,    // Ethereun Mainnet - 1 (ETH)
  2,
  3,    // Ropsten Test Network - 3 (ETH)
  4,    // Rinkeby Test Network - 4 (ETH)
  5,    // Goerli Test Network - 6 (ETH)
  42,   // Kovan Test Network -  42 (ETH)
  137,  // Polygon Matic Network - 137 (MATIC)
  80001 // Polygon Mumbai Test Network - 80001 (MATIC)
];

const injected = new InjectedConnector({
  supportedChainIds: SUPPORTED_CHAINIDS
});

const walletConnect = new WalletConnectConnector({
  rpcUrl: `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`,
  bridge: "https://bridge.walletconnect.org",
  qrcode: true
});

const coinbaseWallet = new WalletLinkConnector({
  url: `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`,
  appName: "The PAC Club",
  supportedChainIds: [1]
});

export const getLibrary = (provider) => {

  const library = new ethers.providers.Web3Provider(provider);


  window.library = library;
  library.pollingInterval = 8000; // frequency provider is polling
  return library;
};

export const setProvider = (type) => {
  window.localStorage.setItem("provider", type);
}

export const CONNECTORS = {
  injected,
  walletConnect,
  coinbaseWallet
};

export const WEB3PROPS = { getLibrary };

export const mintNFTs = async ({
  library,
  address,
  chainId,
  numOfTokens,
  onMintStart,
}) => {

  const signer = library.getSigner();
  const sdk = ThirdwebSDK.fromSigner(signer, "ethereum");
  const contract = sdk.getSignatureDrop(CONTRACT_ADDRESS);

  try {
    
    onMintStart();
    const tx = await contract.claimTo(
      address,
      numOfTokens
    );
    const { 
      id,      // id of NFT claimed
      receipt      // transaction receipt
    } = tx[0]; 

    const { transactionHash, status } = receipt;

    return {
      success: true,
      status: true,
      transactionHash,
      txLink: `https://etherscan.io/tx/${transactionHash}`,
      summary: receipt
    }


  } catch (err) {
    if (typeof err == "object") {
      const error = JSON.stringify(err);
      const errorObj = JSON.parse(error);
      const errorMessage = errorObj.reason;

      return {
        success: false,
        status: errorMessage === "" ? "Please try again later" : errorMessage
      }
      
    }
    return {
      success: false,
      status: false
    }
  }
};

export const getClaimedSupply = async () => {
  try {
    const claimedSupply = await CONTRACT.totalClaimedSupply(); // Big Number
    const count = claimedSupply.toNumber();
    return count;
  } catch (err) {
    console.log("err", err)
    return 0;
  }
};

export const getUnclaimedSupply = async () => {
  try {
    const unClaimedSupply = await CONTRACT.totalUnclaimedSupply(); // Big Number
    const count = unClaimedSupply.toNumber();
    return count;
  } catch (err) {
    console.log("err", err)
    return 0;
  }
};

export const getUserTokenSupply = async (address, library) => {
  try {
    const balance = await CONTRACT.balanceOf(address);
    const userTokenCount = balance.toNumber();
    return userTokenCount;
  } catch (err) {
    return 0;
  }
};