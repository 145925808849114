import { css, StyleSheet } from "aphrodite"
import { UIColors } from "../utils/colors";

const Footer = () => {

  return (
    <div className={css(styles.footer)}>
      {"© Synergy Labs 2022 (Not affiliated with Psychedelics Anonymous)"}
    </div>
  )
};

const styles = StyleSheet.create({
  footer: {
    marginTop: 20,
    fontSize: 12,
    color: UIColors.white(),
    fontWeight: 500
  }
});

export default Footer;