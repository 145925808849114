import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Image,
  HStack,
  useToast
} from '@chakra-ui/react';
import { useWeb3React } from "@web3-react/core";
import { css, StyleSheet } from "aphrodite";
import { UIColors } from '../utils/colors';
import { CONNECTORS, setProvider } from "../utils/web3"

export default function ConnectButton({ button }) {

  const { activate } = useWeb3React();
  const toast = useToast();


  const connectMetamask = async () => {
    try { 
      await activate(CONNECTORS.injected);
      setProvider('mm');
      toast({
        title: 'Wallet Connected!',
        description: "MetaMask Wallet connected succesfully.",
        status: 'success',
        duration: 4000,
        isClosable: true,
        position: 'right',
      })
    } catch (err) {
      console.log("err", err)
    }
  };

  const connectCoinbase = () => {
    activate(CONNECTORS.coinbaseWallet);
    setProvider('cbw');
    // try {
    //   toast({
    //     title: 'Wallet Connected!',
    //     description: "Coinbase Wallet connected successfully.",
    //     status: 'success',
    //     duration: 4000,
    //     isClosable: true,
    //     position: 'top',
    //   })
    // } catch (err) {
    //   console.log("err", err)
    // }
  };
  
  return (
    <Menu>
      <MenuButton>
        {button}
      </MenuButton>
      <MenuList>
        <MenuItem minH={"40px"} onClick={connectMetamask}>
          <HStack w={"100%"} justifyContent={"left"}>
            <Image
              src="/mm.png"
              alt="Metamask Logo"
              width={"25px"}
              height={"25px"}
              borderRadius="3px"
            />
            <span className={css(styles.label)}>
              MetaMask
            </span>
          </HStack>
         </MenuItem>
        <MenuItem  minH={"40px"} onClick={connectCoinbase}>
          <HStack w="100%" justifyContent="left">
            <Image
              src="/cbw.png"
              alt="Coinbase Wallet Logo"
              width={"25px"}
              height={"25px"}
              borderRadius="3px"
            />
            <span className={css(styles.label)}>
              Coinbase Wallet
            </span>         
          </HStack>
        </MenuItem>
      </MenuList>
    </Menu>
  )
};

const styles = StyleSheet.create({
  label: {
    marginLeft: "10px",
    fontFamily: "'Poppins'",
    fontWeight: 600,
    fontSize: 14,
    color:  UIColors.black()
  }
})