import { useState } from "react";
import { css, StyleSheet } from "aphrodite";
import { UIColors } from "../utils/colors";
import SectionLabel from "./SectionLabel";

const Section = ({ label, content, children, style }) => {

  return (
    <div className={css(styles.section, style && style)}>
      <SectionLabel>
        {label}
      </SectionLabel>
      <div className={css(styles.content)}>
        {children}
      </div>
    </div>
  )
};


const styles = StyleSheet.create({
  section: {
    marginBottom: 20,
  },
  content: {
    height: 57,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    color: UIColors.blue(),
  }
});

export default Section;