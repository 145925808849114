import {
  VStack,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { Image } from '@chakra-ui/react';
import { useEffect, useState } from "react";
import { css, StyleSheet } from "aphrodite";
import HyperLink from './HyperLink';
import { UIColors } from '../utils/colors';


const TRANSACTION_CHECK_INTERVAL = 1000;

const MintModal = ({ txSummary, isOpen, onClose, onSuccess }) => {
  const [txStatus, setTxStatus] = useState(null);
  const [txLink, setTxLink] = useState(null);


  useEffect(() => {
  }, [isOpen])

  useEffect(() => {
    if (txSummary) {
      const { status, txLink } = txSummary;
      setTxStatus(status);
      setTxLink(txLink);
      onSuccess();
    }
  }, [txSummary])

  const closeModal = () => {
    // closes modal
    setTxStatus(null);
    setTxLink(null);
    onClose && onClose();
  };

  // /**
  //  * Checks ETHERSCAN for minting transaction status (heartbeat interval)
  //  * @param {String} txHash
  //  */
  //  async function getTransactionReceipt(txHash) {
  //   try {
  //     const receipt = await window.web3.eth.getTransactionReceipt(txHash);

  //     if (receipt) {
  //       if (receipt.status) {
  //         // on success
  //         onSuccess && onSuccess();
  //       }
  //       setTxStatus(receipt.status);
  //     } else {
  //       setTimeout(() => {
  //         getTransactionReceipt(txHash);
  //       }, TRANSACTION_CHECK_INTERVAL);
  //     }
  //   } catch (err) {
  //     // toast.error("Hm something went wrong!")
  //   }
  // }


  const renderTitle = () => {
    if (txStatus === null) {
      return "Minting";
    }

    return txStatus === true ? "Congrats" : "Something went wrong";
  };

  const renderMessage = () => {
    if (txStatus === null) {
      return "";
    } 


    if (txStatus === true) {
      return "Your transaction was successful!"
    } else if (typeof txStatus === "string") {
      return (
        <>
          <span style={{ fontStyle: "italic", textTransform: "uppercase" }}>
            {txStatus}
          </span>
        </>
      )
    } else {
      return "Please try again later."
    }
  };

  const renderIcon = () => {

    if (txStatus === null) {
      return (
        <i
          className={css(styles.icon, styles.loadingIcon) + " fad fa-spinner-third fa-spin"}
        />
      )
    } else if (txStatus !== true) {
      return (
        <i 
          className={css(styles.icon, styles.errorIcon) + " fas fa-exclamation-triangle"}
        />
      )
    } else {
      return (
        <i 
          className={css(styles.icon, styles.successIcon) + " fas fa-check-circle"} 
        />
      )
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={closeModal} isCentered closeOnOverlayClick={txStatus !== null}>
      <ModalOverlay />
      <ModalContent
       w="80vw" 
       style={{
        background: UIColors.bannerBlue(),
        border: `1px solid ${UIColors.bannerBorderBlue()}`,
        borderRadius: 8,
       }}
      >
       {txStatus !== null && <ModalCloseButton color={"#FFF"} />}
        <ModalBody>
          <div className={css(styles.content)}>
            <h2 className={css(styles.title)}>
              {renderTitle()}
            </h2>
            <p style={{ fontSize: 16, fontWeight: "bold", textAlign: "center" }}>
              {renderMessage()}
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {txLink && (
                  <p style={{ fontSize: 16, fontStyle: "italic", margin: "5px 0" }}>
                    <HyperLink href={txLink}>
                      {"Review your mint transaction here."}
                    </HyperLink>
                  </p>
                )}
            </div>
            <p style={{ margin: 0 }}>
              {renderIcon()}
            </p>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};


const styles = StyleSheet.create({
  closeButton: {
    position: "absolute",
    top: 10,
    right: 10,
    cursor: "pointer"
  },
  closeIcon: {
    fontSize: 24,
    color: "#FAFAFA"
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "15px 20px 15px 20px",
    color: UIColors.white()
    // minWidth: 400,
    // "@media only screen and (max-width: 415px)": {
    //   width: "100%",
    //   height: "100vh"
    // }
  },
  title: {
    textTransform: "uppercase",
    fontSize: 24,
    textAlign: "center",
    fontWeight: "bold",
    margin: "10px 0 15px 0"
  },
  subtext: {
    fontSize: 14,
    fontWeight: 300,
    textAlign: "justify",
    textAlignLast: "center",
    paddingBottom: 15,
  },
  button: {
    fontSize: 16,
    margin: "0 auto",
    marginTop: 32,
    width: "fit-content",
    maxWidth: 306,
  },
  icon: {
    fontSize: 50,
    borderRadius: 100,
    margin: "20px 0 10px 0",
  },
  loadingIcon: {
    color: "#FFFF00"
  },
  successIcon: {
    color: "#00F790",
  },
  errorIcon: {
    color: UIColors.red()
  }
});


export default MintModal;