import { css, StyleSheet } from "aphrodite";
import { useEffect, useRef, useState } from "react";
import { UIColors } from "../utils/colors";

const HyperLink = (props) => {
  const { href, style } = props;
  return (
    <a href={href} rel="noopener noreferrer" target="_blank" className={css(styles.hyperlink, style && style)} >
      {props.children}
    </a>
  )
};

const styles = StyleSheet.create({
  hyperlink: {
    color: UIColors.blue(),
    textDecoration: "unset",
    cusor: "pointer",
    ":hover": {
      cusor: "pointer",
      textDecoration: "underline"
    }
  }
});

export default HyperLink;