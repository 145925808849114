import { useState } from "react";
import { css, StyleSheet } from "aphrodite";
import { UIColors } from "../utils/colors";

const Input = ({ onChange, value, MIN = 1, MAX = 5}) => {
  const handleChange = (type) => {
    const currValue = Number(value);

    if (type === "increment") {
      const newValue = Math.min(MAX, currValue + 1);
      onChange(newValue);
    } else if (type === "decrement") {
      const newValue = Math.max(MIN, currValue - 1);
      onChange(newValue);
    }
  }

  return (
    <div className={css(styles.inputContainer)}>
      <div 
        className={css(styles.incrementButton)}
        onClick={() => handleChange("decrement")}
      >
        {"-"}
      </div>
      <div className={css(styles.value)}>
        {value}
      </div>
      <div 
        className={css(styles.incrementButton)}
        onClick={() => handleChange("increment")}
      >
        {"+"}
      </div>

    </div>
  )
};

const styles = StyleSheet.create({
  inputContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  incrementButton: {
    width: 28,
    height: 28,
    borderRadius: "100%",
    display: "flex",
    justifyContent: "center",
    aiignItems: "center",
    background: UIColors.blue(),
    color: UIColors.black(),
    fontWeight: "bold",
    fontSize: 20,
    cursor: "pointer",
    transition: "all ease-in 0.2s",
    userSelect: "none",
    ":hover": {
      background: UIColors.white()
    }
  },
  value: {
    color: UIColors.white(),
    fontSize: 24,
    border: 0,
    fontWeight: 700,
    height: 32,
    padding: "0.75em",
    width: 50,
    margin: 5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxSizing: "border-box",
    textAlign: "center",
    userSelect: "none"
  }
});

export default Input;